export const COLORS = {
  ALICE_BLUE: '#EEF5FF',
  AMARANTH: '#DC3545',
  BACKGROUND: '#FBFBFB',
  BIRD_FLOWER: '#DAD104',
  BLACK: '#000000',
  BLUE: '#4664D1',
  BLUE_LIGHT: '#4780EE',
  BLUE_LIGHT_DISABLED: '#B6CAF0',
  BREADCRUMB_GREY: '#8C8C8C',
  BRONZE_FLESH: '#F78D51',
  CADET_BLUE: '#A3AFC3',
  CYAN: '#007C89',
  CYAN_BLUE: '#3A6CA1',
  CYAN_LIGHT: '#D4FCFF',
  DARING: '#DF5E4C',
  DARK_GRAY: '#8F8F8F',
  DARK_GRAY_HOVER: '#737373',
  DARK_SHADE_OF_GRAY: '#2D2D2D',
  DIM_GRAY: '#707070',
  DISABLE_GRAY: '#808080',
  ECLIPSE: '#3C3A3A',
  ERROR: '#F46666',
  FIRE_RED: '#C71313',
  GRANITE_GRAY: '#565656',
  GRASS_GREEN: '#166534',
  GRAYISH_WHITE: '#FDFDFD',
  GRAY_3: '#595959',
  GRAY_40: '#666666',
  GRAY_43: '#6E6E6E',
  GRAY_83: '#D4D4D4',
  GRAY_89: '#E3E3E3',
  GRAY_94: '#F0F0F0',
  GRAY_HR: '#C4C4C4',
  GRAY_LIGHT: '#E6E6E6',
  GRAY_VERIFIED: '#767676',
  GREAT_LIGHT_SHADE: '#EBEBEB',
  GREEN: '#0B8653',
  GREEN_COSMIC_LATTE: '#D8FFD4',
  GREEN_TEMPO_TEAL: '#018D8F',
  GREEN_TEMPO_TEAL_BG: '#018D8F0D',
  HINT_OF_GREEN: '#F0FDF4',
  HR_DARK_GRAY: '#000000b8',
  HR_GRAY: '#00000012',
  LIGHT_GRAY: '#A5A5A533',
  LIGHT_GREEN: '#E8FBF0',
  LIGHT_YELLOW: '#FFFBD4',
  LIME: '#A9FFBC',
  MAGIC_MINT: '#BBF7D0',
  MAIN_GRAY: '#A5A5A5',
  MIDNIGHT: '#1D2B3B',
  MIDNIGHT_EXPRESS: '#20293A',
  NAVY_BLUE: '#2C13C7',
  NAVY_BLUE_HOVER: '#220e9c',
  NEGATIVE_GREEN: '#6EAEA7',
  NERO: '#1D1D1D',
  NEUTRAL: '#D9D9D9',
  NEUTRAL_DARK: '#525252',
  NIGHT_BLUE: '#2E63EA',
  OCEAN_GREEN: '#49DE80',
  PALE_BLUE: '#EBF1FA',
  PALE_BLUE_FOOTER: '#EBF2FF',
  PAPER_WHITE: '#F9FBFF',
  PAYPAL_YELLOW: '#fde6b8',
  PINK_CLAY: '#FFD4D4',
  PIN_BLUE: '#4d70f8',
  POSITIVE_RED: '#B89797',
  PUNCHLINE: '#212a3bdb',
  PURPLE: '#9852F9',
  REVELL: '#EEEEEEE',
  SPACE_BLUE: '#1F293A',
  STRONG_RED: '#CE1112',
  TROPICAL_BLUE: '#BAD1FF',
  TURQUOISE: '#00D9DB',
  VOLCANO: '#D4380D',
  WHITE: '#FFFFFF',
  WHITE_HOVER: '#F9F9F9',
  WHITE_SMOKE: '#F5F5F5',
  YELLOW: '#F7C451',
  YELLOW_HOVER: '#f4be45',
}

