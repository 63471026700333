import styled from 'styled-components'
import React from 'react'

import { COLORS } from '@probatix/common/constants/colors'
import { MOBILE_WIDTH } from '@probatix/common/constants/design'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledTextAreaWrapper = styled(({ hasError, ...rest }) => (
  <div {...rest} />
))`
  background: ${COLORS.WHITE};
  border-radius: 25px;
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;

  ${({ $hasError }) => $hasError && `
    border-color: ${COLORS.ERROR};
  `}

  ${({ height }) => (height
    ? `height: ${height}px;
      max-height: ${height}px !important;
      min-height: ${height}px !important;
    `
    : 'min-height: 72px;')
}
`

export const StyledLabel = styled.div`
  color: ${COLORS.CADET_BLUE};
  position: absolute;
  left: 0.875rem;
  transition: all 0.25s ease;
  font-size: 0.8rem;
  top: 0.875rem;
  transform: translateY(0);
  z-index: 11;
  user-select: none;
  pointer-events: none;
`

export const StyledTextArea = styled.textarea`
  resize:none;
  background: transparent;
  min-width: 0;
  width: 100%;
  font-size: 0.8rem;
  border: 1px solid ${COLORS.CADET_BLUE};
  position: relative;
  z-index: 10;
  padding: 0.875rem 0.875rem;
  border-radius: 25px;

  ${({ height }) => (height
    ? `height: ${height}px;
      max-height: ${height}px !important;
      min-height: ${height}px !important;
    `
    : 'min-height: 72px;')
}

  &:placeholder-shown ~ ${StyledLabel} {
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.8rem;
  }

  &:focus ~ ${StyledLabel} {
    font-size: 0.8rem;
    top: 0.875rem;
    transform: translateY(0);
  }

  &:focus ~ ${StyledTextAreaWrapper} {
    border-color: ${COLORS.BLUE};
  }
  
  ${({ $hasError }) => ($hasError ? `
      &:focus-visible {
        border-color: ${COLORS.ERROR};
        outline: 0;
      }
  `
    : ` &:focus-visible {
      border-color: ${COLORS.BLUE};
      outline: 0;
    }
  `)}

  &:disabled {
    cursor: not-allowed;
    background: ${COLORS.NEUTRAL};
  }

  @media(max-width: ${MOBILE_WIDTH}px) {
    padding: 36px 0.875rem 12px;

    &:focus ~ ${StyledLabel} {
      top: 11px;
      font-size: 0.875rem;
    }
  }
`
