import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale } from 'chart.js'
import { COLORS } from '@probatix/common/constants/colors'
import {
  StyledDoughnutChart,
  StyledDoughnutChartWrapper,
} from './SingleDoughnutChart.styled'

interface Params {
  inRangeColor?: string;
  inRangeCount?: Number;
  outOfRangeColor?: string;
  outOfRangeCount?: Number;
  rangeType: string;
  totalRangeCount?: Number;
}

const SingleDoughnutChart: React.FC<Params> = ({
  rangeType = 'in_range',
  inRangeColor = COLORS.GREEN_TEMPO_TEAL,
  inRangeCount = 0,
  outOfRangeColor = COLORS.YELLOW,
  outOfRangeCount = 0,
  totalRangeCount,
}) => {
  const inRangeData = {
    datasets: [
      {
        backgroundColor: [inRangeColor, COLORS.GRAY_89],
        borderColor: [inRangeColor, COLORS.GRAY_89],
        borderRadius: [100, 0],
        borderWidth: [2, 0],
        cutout: '65%',
        data: [inRangeCount, Number(totalRangeCount) - Number(inRangeCount)],
        hoverBackgroundColor: [inRangeColor, COLORS.GRAY_89],
        hoverBorderColor: [inRangeColor, COLORS.GRAY_89],
      },
    ],
  }

  const outOfRangeData = {
    datasets: [
      {
        backgroundColor: [outOfRangeColor, COLORS.GRAY_89],
        borderColor: [outOfRangeColor, COLORS.GRAY_89],
        borderRadius: [100, 0],
        borderWidth: [2, 0],
        cutout: '65%',
        data: [outOfRangeCount, Number(totalRangeCount) - Number(outOfRangeCount)],
        hoverBackgroundColor: [outOfRangeColor, COLORS.GRAY_89],
        hoverBorderColor: [outOfRangeColor, COLORS.GRAY_89],
      },
    ],
  }

  return (
    <StyledDoughnutChartWrapper>
      <StyledDoughnutChart
        $zIndex={1}
        data={
          'inRange' === rangeType
            ? inRangeData
            : outOfRangeData
        }
        options={{ events: null }}
      />
    </StyledDoughnutChartWrapper>
  )
}

export default SingleDoughnutChart

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale)
