import color from 'color'
import { COLORS } from '@probatix/common/constants/colors'
import styled from 'styled-components'

interface IStyledLink {
  isSmallScreen?: boolean,
  last?: boolean
}

export const StyledFooter = styled.div`
  height: 50px;
  z-index: 2;
  bottom: 0px;
  background: transparent;
  margin: auto;
  padding-bottom: 100px;
  

  ${(props) => (
    (props.relative) ? `
      position: relative;
      text-align: center;
      margin: auto;
    `
      : `
      position: fixed;
      transform: translateX(-50%);
      left: 50%;
    `
  )}
`

export const StyledLink = styled.a<IStyledLink>`
    color: ${color(COLORS.BLACK).alpha(0.45)};
    font-size: 0.875rem;
    margin-top: 19px;
    ${(props) => ((props.last) && !(props.isSmallScreen) && ' margin-right: -40px;')}
    cursor: pointer;

    ${(props) => !(props.isSmallScreen) && ` 
      &:after {
        display: inline-block;
        content: '/';
        margin: 0 10px;
        color: ${color(COLORS.BLACK).alpha(0.45)};
      }`
}

    &:hover {
      cursor: pointer;
      color: ${color(COLORS.BLACK).alpha(0.85)};
    }
`
