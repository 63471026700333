import eventBus from '@probatix/common/utils/eventBus'

export interface SessionStorageInterface {
  get: (key: string) => any
  remove: (key: string) => any
  set: (key: string, value: any) => void
}

const SessionStorage: SessionStorageInterface = {
  get: (key: string) => window.sessionStorage.getItem(key),
  remove: (key: string) => {
    window.sessionStorage.removeItem(key)
    eventBus.dispatch(key, 'remove')
  },
  set: (key: string, value: any) => {
    window.sessionStorage.setItem(key, value)
    eventBus.dispatch(key, value)
  },
}

export default SessionStorage
