import { createSlice } from '@reduxjs/toolkit'

import { Combine } from '@probatix/common/utils/typescript'

import { apiCallInitialized } from '@probatix/common/services/toolkit/api/apiService.actions'

import { getQueryString } from './url'

export interface BasicState {
  fetching: boolean
  initialized: boolean
}

export interface BasicListInitialState extends BasicState {
  data: any
  errors: any
}

export interface BasicSingleInitialState extends BasicState {
  data: any
  errors: null
  submitting: boolean
}

export type ActionParams<D, T extends Object = object, M extends Object = object> = {
  authorization?: boolean,
  customApiPrefix?: string,
  customEntrypoint?: string,
  customHeaders?: {},
  data?: D,
  locale?: string,
  meta?: M,
  onError?: string,
  onErrorCallback?: Function,
  onFinallyCallback?: Function,
  onInit?: string,
  onSuccess?: string,
  onSuccessCallback?: Function,
  overrideResponseData?: object,
  params?: T,
  silent?: boolean,
  status?: number,
  url?: string,
  withCredentials?: boolean
}

export const getBasicListInitialState = () => ({
  data: null,
  errors: null,
  fetching: false,
  initialized: false,
})

export const getBasicSingleInitialState = () => ({
  data: null,
  errors: null,
  fetching: false,
  initialized: false,
  submitting: false,
})

export const getBasicListReducerSlice = <T>(
  initialState: Combine<T, BasicListInitialState>,
  actionPrefix: string,
  customReducers?: any,
  extraReducers?: any,
) => createSlice({
  extraReducers,
  initialState,
  name: 'list',
  reducers: {
     
    [`${actionPrefix}ListFetching`]: (list, action) => {
      const { silent } = action
      if (silent) {
        return list
      }

      list.initialized = list.initialized || true
      list.errors = null
      list.fetching = true
    },
    [`${actionPrefix}ListSuccess`]: (list, action) => {
      const { silent } = action

      if (silent) {
        return list
      }

      list.data = action.payload
      list.fetching = false
    },
    [`${actionPrefix}ListFailed`]: (list, action) => {
      const { silent } = action

      if (silent) {
        return list
      }

      list.errors = action.payload
      list.fetching = false
    },
    [`${actionPrefix}ListReset`]: () => initialState,
    ...customReducers,
  },
   
})

export const getBasicSingleReducerSlice = <T>(
  initialState: Combine<T, BasicSingleInitialState>,
  actionPrefix: string,
  customReducers?: any,
  extraReducers?: any,
) => createSlice({
  extraReducers,
  initialState,
  name: 'single',
  reducers: {
    [`${actionPrefix}SingleFetching`]: (single) => {
      single.initialized = single.initialized || true
      single.errors = null
      single.fetching = true
    },
    [`${actionPrefix}SingleSuccess`]: (single, action) => {
      single.data = { ...single.data, ...action.payload }
      single.fetching = false
      single.submitting = false
    },
    [`${actionPrefix}SingleSubmitting`]: (single, action) => {
      single.data = action.payload || single.data
      single.errors = null
      single.submitting = true
    },
    [`${actionPrefix}SingleFailed`]: (single, action) => {
      single.errors = action.payload
      single.fetching = false
      single.submitting = false
    },
    [`${actionPrefix}SingleResetErrors`]: (single) => {
      single.errors = null
    },
    [`${actionPrefix}SingleReset`]: () => initialState,
    ...customReducers,
  },
})

export const getBasicSliceActions = (actions) => ({
  ...actions,
})

export const getBasicListAction = ({
  authorization,
  customApiPrefix,
  customEntrypoint,
  customHeaders,
  meta = {},
  onError,
  onErrorCallback,
  onFinallyCallback,
  onInit,
  onSuccess,
  onSuccessCallback,
  params,
  silent,
  url,
  withCredentials,
}: ActionParams<object, object>) => (dispatch) => {
  dispatch(
    // @ts-ignore
    apiCallInitialized({
      authorization,
      customApiPrefix,
      customEntrypoint,
      customHeaders,
      meta,
      method: 'GET',
      onError,
      onErrorCallback,
      onFinallyCallback,
      onInit,
      onSuccess,
      onSuccessCallback,
      silent,
      url: params ? `${url}?${getQueryString(params)}` : url,
      withCredentials,
    }),
  )
}

export const getBasicCreateSingleAction = ({
  authorization = true,
  url,
  customApiPrefix,
  customEntrypoint,
  customHeaders,
  params = {},
  data = {},
  meta = {},
  onInit,
  onSuccess,
  onError,
  onSuccessCallback,
  onErrorCallback,
  onFinallyCallback,
  withCredentials,
  overrideResponseData,
}: ActionParams<object, object>) => (dispatch) => {
  dispatch(
    // @ts-ignore
    apiCallInitialized({
      authorization,
      customApiPrefix,
      customEntrypoint,
      customHeaders,
      data,
      meta,
      method: 'POST',
      onError,
      onErrorCallback,
      onFinallyCallback,
      onInit,
      onSuccess,
      onSuccessCallback,
      overrideResponseData,
      url: Object.keys(params).length ? `${url}?${getQueryString(params)}` : url,
      withCredentials,
    }),
  )
}

export const getBasicUpdateSingleAction = ({
  url,
  params = {},
  customEntrypoint,
  customHeaders,
  data = {},
  meta = {},
  onInit,
  onSuccess,
  onError,
  onSuccessCallback,
  onErrorCallback,
  onFinallyCallback,
  withCredentials,
}: ActionParams<object, object>) => (dispatch) => dispatch(
  // @ts-ignore
  apiCallInitialized({
    authorization: true,
    customEntrypoint,
    customHeaders,
    data,
    meta,
    method: 'PUT',
    onError,
    onErrorCallback,
    onFinallyCallback,
    onInit,
    onSuccess,
    onSuccessCallback,
    url: `${url}?${getQueryString(params)}`,
    withCredentials,
  }),
)

export const getBasicSingleAction = ({
  authorization = true,
  url,
  customEntrypoint,
  customHeaders,
  meta = {},
  params = {},
  silent,
  onInit,
  onSuccess,
  onError,
  onSuccessCallback,
  onErrorCallback,
  onFinallyCallback,
  withCredentials,
}: ActionParams<object, object>) => (dispatch) => dispatch(
  // @ts-ignore
  apiCallInitialized({
    authorization,
    customEntrypoint,
    customHeaders,
    meta,
    method: 'GET',
    onError,
    onErrorCallback,
    onFinallyCallback,
    onInit,
    onSuccess,
    onSuccessCallback,
    silent,
    url: `${url}?${getQueryString(params)}`,
    withCredentials,
  }),
)

export const getBasicDeleteSingleAction = ({
  authorization = true,
  url,
  customEntrypoint,
  customHeaders,
  meta = {},
  params = {},
  silent,
  onInit,
  onSuccess,
  onError,
  onSuccessCallback,
  onErrorCallback,
  onFinallyCallback,
  withCredentials,
}: ActionParams<object, object>) => (dispatch) => dispatch(
  // @ts-ignore
  apiCallInitialized({
    authorization,
    customEntrypoint,
    customHeaders,
    meta,
    method: 'DELETE',
    onError,
    onErrorCallback,
    onFinallyCallback,
    onInit,
    onSuccess,
    onSuccessCallback,
    silent,
    url: `${url}?${getQueryString(params)}`,
    withCredentials,
  }),
)
