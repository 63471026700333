
import distance from '@turf/distance'
import { Units } from '@turf/helpers'

import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson'
import { GeoData, GeoLocation, Location } from './index.model'

export const ENV: string = process.env.REACT_APP_APP_ENV || 'local'
export const URL: string = ('local' === ENV) ? 'https://dev.probatix.localhost' : ''

export const IsActiveAndArchived = 'archived=false'
export const myProbatixActive = `tenant.myProbatixActive=true&${IsActiveAndArchived}`
export const noPagination = 'pagination=false'
export const basePathLocations = (customEntryPoint) => (
  `${customEntryPoint ? URL : process.env.REACT_APP_CORE_API_ENTRYPOINT}/api/v3/locations`
)

/* eslint-disable */
export const groupedByVicinity = (data) => {
  const grouped = {}

  data.forEach((item) => {
    const key = `${item.locality}- ${item.vicinity}`
    if (!grouped[key]) {
      grouped[key] = []
    }
    grouped[key].push(item)
  })

  return grouped
}

const geolocationAPI = navigator.geolocation

export const getCurrentLocation = () => new Promise<number[] | null>((resolve, reject) => {
  if (geolocationAPI) {
    geolocationAPI.getCurrentPosition(
      (position) => {
        const { coords } = position
        resolve([coords.longitude, coords.latitude])
      },
      () => {
        console.log('Something went wrong getting your position!')
        reject(null)
      },
    )
  } else {
    reject(null)
  }

})

export const changeUnit = (away) => {
  if (!away) {
    return null
  }

  if (1 < away) {
    return `${away.toString().replace('.', ',')} km`
  }

  return `${(Number(away) * 1000).toString().replace('.', ',')} m`

}

const returnDistance = (geometry, currentLocation) => {
  const units: Units = 'kilometers'
  const options = { units }
  const to = [geometry.coordinates[1], geometry.coordinates[0]]
  const dis = distance(to, currentLocation!, options)

  return Number(dis.toFixed(2)) || null
}

function deepClone(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  let clone = Array.isArray(obj) ? [] : {};

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      clone[key] = deepClone(obj[key]);
    }
  }

  return clone;
}

export const addDistanceToLocation = (locations: Location[], coordinates?: any) => {
  try {
    if (coordinates) {
      return locations.map((element) => {
        if (element.geometry) {
          const updatedElement = deepClone(element)
          updatedElement.distance = returnDistance(updatedElement.geometry, coordinates)

          return updatedElement
        }

        return element
      })
        .filter((element) => element.geometry) // Filter out elements with null geometry
        .sort((a, b) => {
          if (null != a.distance && null != b.distance) {
            return a.distance - b.distance
          }

          return 0
        })
    }

    return locations
  } catch (error) {

    return locations
  }
}

export const formatFeatures = (data) => {
  const items: FeatureCollection<Geometry, GeoJsonProperties> = { features: [], type: 'FeatureCollection' }
  data!.forEach((element: GeoLocation) => {
    const item = {
      availableProductTypes: element!.availableProductTypes,
      distance: element!.distance,
      geometry: element!.geometry! as Geometry,
      locationCode: element!.locationCode,
      properties: {
        address: element!.properties!.locationFormattedAddress!,
        city: element!.properties!.providerName!,
        count: element!.properties!.locationVicinity!,
        country: element!.properties!.locationDescription!,
        description: element!.properties!.locationDescription!,
        id: element.uuid,
        link: element!.properties!.locationBookingPageUrl!,
        name: element!.properties!.locationName!,
        phone: element!.properties!.locationVicinity!,
        phoneFormatted: element!.properties!.locationDescription!,
        postalCode: element!.properties!.locationDescription!,
        state: element!.properties!.locationDescription!,
      } as GeoJsonProperties,
      type: 'Feature' as any,
      uuid: element!.uuid,
    }
    items!.features.push(item)
  })

  return items!
}

export const formatFeature = (element) => {
  const item: GeoData = {
    availableProductTypes: element!.availableProductTypes,
    geometry: element!.geometry! as Geometry,
    locationCode: element!.locationCode,
    properties: {
      address: element!.properties!.locationFormattedAddress!,
      city: element!.properties!.providerName!,
      count: element!.properties!.locationVicinity!,
      country: element!.properties!.locationDescription!,
      description: element!.properties!.locationDescription!,
      id: element.uuid,
      link: element!.properties!.locationBookingPageUrl!,
      name: element!.properties!.locationName!,
      phone: element!.properties!.locationVicinity!,
      phoneFormatted: element!.properties!.locationDescription!,
      postalCode: element!.properties!.locationDescription!,
      state: element!.properties!.locationDescription!,
    } as GeoJsonProperties,
    type: 'Feature' as any,
    uuid: element!.uuid,
  }

  return item
}
