import styled from 'styled-components'

export const StyledNavigation = styled.div`
  height: 80px;
  position: fixed;
  width: 100%;
  z-index: 2;
  background: white;
  top: 0px;
`

export const StyledMobileLogin = styled.a`
  background: white;
  border-radius: 39px;
`

export const StyledDesktopLogin = styled.a`
  background: rgba(209, 209, 209, 0.72);
  border-radius: 39px;
  padding: 10px;
  display: flex;
`
