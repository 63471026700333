import { Nullable } from '@probatix/common/models/global'
import { BasicListInitialState, BasicSingleInitialState } from '@probatix/common/utils/service'

export const enum Gender {
  diverse = 'D',
  female = 'F',
  male = 'M',
  unknown = 'X'
}

export interface PersonBasic {
  dateOfBirth: Date | string
  firstName: string
  gender: Gender
  lastName: string
  personalIdNumber?: string
}

export interface PersonContact {
  eligibilitySelfConfirmationReason?: string
  email?: string
  phoneNumber?: string
}

export interface Address {
  city?: string
  country?: string
  streetAndHouseNumber?: string,
  zipCode?: string
}

export interface PersonalAddress {
  addressCity?: string
  addressCountry?: string
  addressStreetAndHouseNumber?: string
  addressZipCode?: string
}

export interface BillingAddress {
  billingAddressCity?: string
  billingAddressCountry?: string
  billingAddressStreet?: string
  billingAddressZipCode?: string
  company?: string
}

export interface Insurance extends PersonBasic, PersonContact, PersonalAddress {
  healthInsuranceCompanyNumber: string
  healthInsurancePolicyNumber: string
}

export interface Customer extends Address, Insurance, PersonBasic, PersonContact, PersonalAddress, BillingAddress {
  selected?: boolean
  uuid?: string
}

export interface Patient extends Insurance, PersonBasic, PersonContact, PersonalAddress {
  myProbatixUserUuid?: string
}

export interface User extends Insurance, PersonBasic, PersonContact, Address, BillingAddress {
  userUuid?: string
  uuid?: string
}

export interface AdditionalPerson {
  address: PersonalAddress
  isDirty?: boolean
  selected: boolean
  uuid?: string
  values: PersonBasic & PersonContact & Insurance
  wasMainContact?: boolean
}

export interface UsersSingleState extends BasicSingleInitialState {
  data: Nullable<User>
}

export interface UsersListState extends BasicListInitialState {
  data: Nullable<User[]>
}

export interface UserState {
  list: UsersListState
  single: UsersSingleState
}

export interface UserRootState {
  user: UserState
}
