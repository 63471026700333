import * as React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateField } from '@mui/x-date-pickers/DateField'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { deDE, enUS } from '@mui/x-date-pickers/locales'

import i18n, {
  LOCALES as supportedLocales,
} from '@probatix/common/translations'
import useSmallScreens from '@probatix/common/hooks/useSmallScreens'
import { useOnClickOutside } from '@probatix/common/hooks'

import { INPUT_HEIGHT_DESKTOP, INPUT_HEIGHT_MOBILE } from '@probatix/common/constants/design'
import { StyledTextField } from './BaseDateField.styled'

const germanLocale = deDE.components.MuiLocalizationProvider.defaultProps.localeText
const englishLocale = enUS.components.MuiLocalizationProvider.defaultProps.localeText

type BaseDateFieldProps = {
  checkout?: boolean;
  control?: any,
  defaultValue?: Date;
  hasError?: boolean;
  label: React.ReactNode;
  locale: string;
  name: string;
  onChange: Function,
  registerName: string;
  setValue: Function;
}

const BaseDateField = React.forwardRef(
  (
    {
      checkout,
      defaultValue,
      locale = i18n.language,
      label,
      hasError,
      name,
      onChange,
      setValue,
      registerName,
    }: BaseDateFieldProps,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref,
  ) => {
    const [isSmallScreen] = useSmallScreens()
    const height = isSmallScreen ? INPUT_HEIGHT_MOBILE : INPUT_HEIGHT_DESKTOP
    const [value, setCustomValue] = React.useState<Date | null>(defaultValue || null)
    const inputRef = React.useRef<any>(null)

    const customTextField = React.useCallback(
      (props) => (
        <StyledTextField
          checkout={checkout}
          {...props}
        />
      ),
      [checkout],
    )

    const handleChangeValue = (newValue) => {
      setCustomValue(newValue)
      onChange(newValue)
      setValue(registerName, newValue)
    }

    const handleDateFocus = () => {
      try {
        const inputValue = inputRef.current?.value.toString().replace(/[^\d.]/g, '')
        const isCorrectDate = 10 === inputValue.length
        if (!isCorrectDate) {
          handleChangeValue(null)
        }
      } catch (error) {
        handleChangeValue(null)
      }
    }
    useOnClickOutside(inputRef, handleDateFocus)

    return (
      <LocalizationProvider
        adapterLocale={supportedLocales[locale]}
        dateAdapter={AdapterDateFns}
        localeText={'de' === locale ? germanLocale : englishLocale}
      >
        <DateField
          format="dd.MM.yyyy"
          inputRef={inputRef}
          label={label}
          slotProps={{
            textField: {
              error: hasError,
              fullWidth: true,
              name,
              sx: {
                borderRadius: checkout ? '1.375rem' : '0.3125rem',
                height: checkout ? '4rem' : height,
              },
            },
          }}
          slots={{
            textField: customTextField,
          }}
          value={value}
          onChange={handleChangeValue}
        />
      </LocalizationProvider>
    )
  },
)

export default BaseDateField

