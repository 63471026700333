import styled from 'styled-components'
import { COLORS } from '@probatix/common/constants/colors'

export const StyledFullPageBackgroundWrapper = styled.div`
  background: ${COLORS.WHITE_SMOKE};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

`
