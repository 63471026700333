import { PayloadAction } from '@reduxjs/toolkit'

import { ActionParams } from '@probatix/common/utils/service'

import { testsServiceActions } from '@probatix/common/services/toolkit/tests'

import { SESSION_KEY } from 'config/app'

import { RootState } from '../reducers'

type Action = PayloadAction<ActionParams<object>>

const testsFormPersistMiddleware = ({ getState }) => (next) => async (action: Action) => {
  const { type } = action

  if (!type.includes('testForm') || testsServiceActions.loadData.match(action)) {
    return next(action)
  }

  const results = next(action)

  const { tests } = getState() as RootState

  window.sessionStorage.setItem(SESSION_KEY, JSON.stringify(tests.form))

  return results
}

export default testsFormPersistMiddleware
