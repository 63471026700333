import styled from 'styled-components'
import { Line } from 'react-chartjs-2'

interface IStyledLineChart {
  width: string
}
export const StyledLineChartWrapper = styled.div<IStyledLineChart>`
  width: 100%;
  min-width: 300px;
`

export const StyledLineChart = styled(Line)`
  margin: auto;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  padding: 11px;
`
