export interface EventBusInterface {
  dispatch: (event: string, data: any) => void
  on: (event: string, callback: Function) => void
  remove: (event: string, callback?: Function) => void
}

const eventBus: EventBusInterface = {
  dispatch(event, data: any) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }))
  },
  on(event, callback) {
    document.addEventListener(event, (e: any) => callback(e.detail))
  },
  remove(event, callback?) {
    document.removeEventListener(event, callback as any)
  },
}

export default eventBus
