import Cookies from 'js-cookie'
import parseISO from 'date-fns/parseISO'

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useRoutes } from 'react-router-dom'
import SnackbarProvider from 'react-simple-snackbar'
import { useTranslation } from 'react-i18next'

import { AuthenticatedUser } from '@probatix/common/services/toolkit/auth/auth.model'

import { AUTH_SUCCESSFUL_KEY } from '@probatix/common/services/toolkit/auth/auth.constants'

import { testsServiceActions } from '@probatix/common/services/toolkit/tests'
import { authServiceActions } from '@probatix/common/services/toolkit/auth'
import { translationsServiceActions } from '@probatix/common/services/toolkit/translations'

import { Spinner } from '@probatix/common/components/atoms'
import { LOCALES } from '@probatix/common/translations'

import { API_MY_PROBATIX_ENTRYPOINT, SESSION_KEY } from 'config/app'

import Routing from 'core/routings'
import Meta from 'meta'

import Styled from 'styled'

import { getLocale } from 'utils'

const App = () => {
  const dispatch = useDispatch()
  const lang = navigator.language
  const { i18n } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [authInitialized, setAuthIsInitialized] = useState<boolean>(false)
  const [transInitialized, setTransIsInitialized] = useState<boolean>(false)

  const routes = useRoutes(Routing)

  const authUser = () => {
    if (Cookies.get(AUTH_SUCCESSFUL_KEY)) {
      dispatch(authServiceActions.me({
        customEntrypoint: API_MY_PROBATIX_ENTRYPOINT,
        onErrorCallback: () => Cookies.remove(AUTH_SUCCESSFUL_KEY),
        onSuccessCallback: (response: AuthenticatedUser) => {
          dispatch(authServiceActions.setAuthData(response))
        },
      }))
    }

    setAuthIsInitialized(true)
  }

  useEffect((): void => {
    const formData = window.sessionStorage.getItem(SESSION_KEY)
    if (!formData) {
      authUser()

      return
    }

    const parsedData = JSON.parse(formData || '')
    dispatch(testsServiceActions.loadData({
      ...parsedData,
      time: parsedData.time && parseISO(parsedData.time),
    }))

    authUser()
  }, [])

  useEffect(() => {
    // App.tsx is not aware of the :locale param from the router, this is why the workaround needs to be implemented
    // to be able to determine the correct locale from the URL at the application start (to load correct translations)
    // and reduce the side effects to the user
    const locale = getLocale(pathname, i18n)
    const langCode = lang.slice(0, 2)
    const preferredLang = Object.keys(LOCALES).includes(langCode) ? langCode : 'de'
    let selectedLang = preferredLang
    if ('/' === window.location.pathname) {
      i18n.changeLanguage(preferredLang)
      navigate(`${preferredLang}/pick-slot`)
    } else if (locale) {
      selectedLang = locale
      i18n.changeLanguage(locale)
    }

    dispatch(translationsServiceActions.get({
      meta: { locale: selectedLang },
      onSuccessCallback: (res) => {
        i18n.addResourceBundle(selectedLang!, 'translation', res)
        setTransIsInitialized(true)
      },
    }))
  }, [])

  if (!authInitialized || !transInitialized) {
    return <Spinner />
  }

  return (
    <SnackbarProvider>
      <Styled />
      <Meta lang="de" />
      {(authInitialized && transInitialized) && routes}
    </SnackbarProvider>
  )
}

export default App
