import styled from 'styled-components'

import React from 'react'

import { COLORS } from '@probatix/common/constants/colors'
import { MOBILE_WIDTH } from '@probatix/common/constants/design'

import { BoxProps } from './Box.model'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledBox = styled(({ cursor, minHeight, radius, width, ...rest }) => <div {...rest} />) <BoxProps>`
  padding: 12px;
  background: ${COLORS.WHITE};
  position: relative;
  border-radius: 25px;
  width: max-content;
  padding-right: 30px;

  ${({ background }) => background && `
    background: ${background};
    overflow: hidden;
  `}

  ${({ radius }) => radius && `
    border-radius: ${radius}px;
  `}

  ${({ width }) => width && `
    width: ${width};
  `}

  ${({ minHeight }) => minHeight && `
    min-height: ${minHeight};
  `}

  ${({ cursor }) => cursor && `
    cursor: ${cursor};
  `}
  
  @media(max-width: ${MOBILE_WIDTH}px) {
    border: 0 !important;
    box-shadow: none;
  }
`
